import style from './Info.module.css';
import PageHeading from '../PageHeading';
import SectionHeading from '../SectionHeading';
import InfoList from '../InfoList';

export default function Info() {
  
  const infoListOne = {

    buttonText: [
      '1. How quickly can I sell my home?', 
      '2. I have a lein on my house, how will that affect the sale?', 
      '3. I have a tenant, can they stay in the home?', 
      '4. I live out of the state and the house is vacant, can you help me?',
      '5. What is creative financing?',
    ],
    dropDownText: [
      'Our goal is to get your home under contract and close it within 30 days.',
      'During closing, leins are paid first, then fees. Afterwards, you will receive the difference.',
      'If you want your tenant to stay, we will take over your home as a rental property to allow your tenant to stay as along as agreed upon.',
      'We are just as successful working with homeowners remotely as we are if you were in town.',
      'There is more than one way to buy and sell a home. If you are in a unique situation, we will work with mortgage companies, banks, and other investors until we find the right fit to make the sale happen.',
      ]

  };

  const infoListTwo = {

    buttonText: [
      '6. I have to move out now. Can I get money up front to help me with the move?',
      '7. My home is in need of repair, can I sell it as is?',
      '8. My home is under probate, can you help me now?',
      '9. I still have a mortgage on my home, can I still sell it?',
      '10. I want to sell-by-owner, do you charge a fee?',
    ],
    dropDownText: [
      'We know that sometimes circumstances are not ideal. We will try to assist in any way possible to help your move go smoothly.',
      'As-is is our specialty! If your home is a time capsule, we will give you the speed and conveience of a quick close without showings and possibly without an inspection.',
      'We\'d love to advise you before losing your home to the bank. Our goal is to have everyone win.',
      'Its ideal to work with us if you have enough mortgage balance for you to still make a profit.',
      'We love For Sale By Owners! If market conditions are favorable for your property and location, we\'ll do our best to get you the full value for the as-is condition of your home without realtor fees.',
      ]

  };

  infoListOne.itemCount = infoListOne.buttonText.length;
  infoListTwo.itemCount = infoListTwo.buttonText.length;

  const sectionHeadingAlteranteStyle = {

    textAlign: 'center',
    marginBottom: '2.5rem',

  }

  return (

    <section className={style.container}>
      <PageHeading pageHeading={'Info'}/>
      <section className={style.innerContainer}>
        <SectionHeading sectionHeading={'Frequently Asked Questions'} alternateStyle={sectionHeadingAlteranteStyle}/>
        <div className={style.containerWrapper}>
          <InfoList displayInformation={infoListOne}/>
        </div>
        <div className={style.containerWrapper}>
          <InfoList displayInformation={infoListTwo}/>
        </div>
      </section>
    </section>

  )

}