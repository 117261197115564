import { Link } from 'react-router-dom';
import styles from './Nav.module.css';

export default function Nav() {

  return (
    <nav className={styles.navigation}>
      <ul>
        <li><Link to='/'>Aloft</Link></li>
        <li><Link to='/about'>About</Link></li>
        <li><Link to='/info'>Info</Link></li>
        <li><Link to='/contact' >Contact</Link></li>
        {/* <li><Link to='/projects'>Projects</Link></li> */}
      </ul>
    </nav>
  )

}