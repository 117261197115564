import InfoButton from './InfoButton';
import style from './InfoList.module.css'

export default function InfoList({displayInformation}) {

  let listItems = [];

  for(let i = 0; i < displayInformation.itemCount; i++) {
    listItems.push(
    <InfoButton 
      key={i} 
      buttonText={displayInformation.buttonText[i]} 
      dropDownText={displayInformation.dropDownText[i]}
    />)
  }

  return (

    <ul className={style.listContainer}>
      {listItems}
    </ul>

  )

}