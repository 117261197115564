import SectionHeading from './SectionHeading'
import Description from './Description';
import InfoList from './InfoList';
import style from './ServiceDisplay.module.css'

export default function ServiceDisplay({ displayInformation }) {

  const alternateDescriptionStyle = {
    height: '70px',
  }

  return (
    <section className={style.serviceDisplay}> 
      <SectionHeading sectionHeading={displayInformation.sectionHeading}></SectionHeading>
      <Description description={displayInformation.description} alternateStyle={alternateDescriptionStyle}lternateStyle></Description>
      <InfoList displayInformation={displayInformation}></InfoList>
    </section>
  )

}