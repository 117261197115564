import style from './About.module.css';
import daniellePicture from '../../images/daniellehalfportrait.jpg';
import janetPicture from '../../images/janethalfportrait.jpg';
import penelopePicture from '../../images/penelopehalfportrait.jpg';
import nathanielPicture from '../../images/nathanielhalfportrait.jpg'
import PageHeading from '../PageHeading';
import SectionHeading from '../SectionHeading';
import Description from '../Description';
import Profile from '../Profile';

export default function About() {

  const danielleBrown = {

    name: 'Danielle Brown',
    profileImage: daniellePicture,
    imageAlt: 'Danielle Brown',
    occupation: 'Invester - Dispositions',
    phoneNumber: '904-373-8023',
    email: 'danille@aloftpropertiesllc.com',

  }

  const janetBrown = {

    name: 'Janet Brown',
    profileImage: janetPicture,
    imageAlt: 'Janet Brown',
    occupation: 'Investor - Acquisitions',
    phoneNumber: '904-723-8096',
    email: 'janet@aloftpropertiesllc.com',

  }

  const nathanielErskine = {

    name: 'Nathaniel Erskine',
    profileImage: nathanielPicture,
    imageAlt: 'Nathaniel Erskine',
    occupation: 'Carpenter - Fix & Flip',
    phoneNumber: null,
    email: 'nathaniel@aloftpropertiesllc.com',

  }

  const penelopePfeiffer = {

    name: 'Penelope Pfeiffer',
    profileImage: penelopePicture,
    imageAlt: 'Penelope Pfeiffer',
    occupation: 'Designer - Consultant',
    phoneNumber: null,
    email: 'penelope@aloftpropertiesllc.com',

  }

  const alternateSectionHeadingStyle = {
    textAlign: 'center',
    margin: '2rem 0 2rem 0',
    fontSize: '2.3rem',
    width: '100%',
  }

  const alternateDescriptionStyle = {

    width: '80%',
    margin: '2rem auto 2rem auto',
    padding: '1rem',
    textAlign: 'center',
    border: 'solid black 3px',
    borderRadius: '10px',

  }

  return (
    <section className={style.container}>
      <PageHeading pageHeading={'About'}/>
      <section className={style.innerContainer}>
        <SectionHeading sectionHeading={'Meet the team'} alternateStyle={alternateSectionHeadingStyle}/>
        <Profile personInfo={ danielleBrown }/>
        <Profile personInfo={ janetBrown }/>
        <Profile personInfo={ penelopePfeiffer }/>
        <Profile personInfo={ nathanielErskine }/>
        <Description description={'We are a team that is dedicated to giving fair prices to sellers and great value to our buyers.'} alternateStyle={alternateDescriptionStyle}/>
      </section>
    </section>
  )
}