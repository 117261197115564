import 'react-toastify/dist/ReactToastify.css';
import style from './Contact.module.css'
import PageHeading from '../PageHeading';
import ContactForm from '../ContactForm';
import { ToastContainer } from 'react-toastify';

export default function Contact() {

  return (

    <section>
      <PageHeading pageHeading={'Contact'}/>
      <section className={style.formContainer}>
        <ContactForm />
      </section>
      <ToastContainer
          className={style.toastContainer}
          position="top-center"
          autoClose={false}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
    </section>

  )
}