import style from './ContactForm.module.css';
import emailjs from '@emailjs/browser';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useState, useRef } from 'react';

export default function ContactForm() {

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const form = useRef();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const sendEmail = () => {

    setIsButtonDisabled(true);

    emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, form.current, process.env.REACT_APP_PUBLIC_KEY)
      .then((result) => {
        form.current.reset();
        toast.success('Message was sent successfully!');
        setIsButtonDisabled(false);
      }, (error) => {
        toast.error('Failed to send message. Please try again.');
        setIsButtonDisabled(false);
      });
  };

  return (
    <form ref={form} className={style.contactForm} onSubmit={handleSubmit(sendEmail)}>
      <div className={style.recipientContainer}>
        <label htmlFor='email_recipient'>Recipient</label>
        <select className={style.recipientInput} id='email_recipient' name='email_recipient' {...register('email_recipient', { required: {value: true, message: 'Recipient is required'} })}>
          <option></option>
          <option>danielle@aloftpropertiesllc.com</option>
          <option>janet@aloftpropertiesllc.com</option>
          <option>penelope@aloftpropertiesllc.com</option>
          <option>nathaniel@aloftpropertiesllc.com</option>
        </select>
        <p className={style.errorMessage}>{errors.email_recipient?.message}</p>
      </div>
      <div className={style.inputContainer}>
        <label className={style.nameLabel} htmlFor='from_name'>Name</label>
        <input className={style.nameInput} id='from_name' name='from_name' type='text' placeholder='Name' {...register('from_name', { required: { value: true, message: 'Name is required'}, maxLength: 50 })}/>
        <p className={style.erorrMessage}>{errors.from_name?.message}</p>
      </div>
      <div className={style.inputContainer}>
        <label className={style.emailLabel} htmlFor='user_email'>Email</label>
        <input className={style.emailInput} id='user_email' name='user_email' type='text' placeholder='example@gmail.com' {...register('user_email', { required: { value: true, message: 'Email is required'}, maxLength: 50 })}/>
        <p className={style.erorrMessage}>{errors.user_email?.message}</p>
      </div>
      <div className={style.textAreaContainer}>
        <label htmlFor='message'>Message</label>
        <textarea id='message' name='message' placeholder='Message' {...register('message', { required: { value: true, message: 'Message is required'}, maxLength: 600 })}></textarea>
        <p className={style.errorMessage}>{errors.message?.message}</p>
      </div>
      <button className={isButtonDisabled ? style.disabledButton : style.enabledButton} disabled={isButtonDisabled}>Submit</button>
    </form>
  )
}