import style from "./Home.module.css";
import aloftLogo from "../../images/companylogo.png";
import CircleImage from "../CircleImage";
import Title from "../Title";
import Tagline from "../Tagline";
import ServiceDisplay from "../ServiceDisplay";

export default function Home() {
  const assistingSellers = {
    sectionHeading: "Assisting Sellers",
    description:
      'We get your property under contract quickly and our motto is to "never cancel a deal."',
    itemCount: 4,
    buttonText: [
      "Getting the full value of your property",
      "Closing Quickly",
      "No Tedious Showings",
      "Creative Closing Strategies",
    ],
    dropDownText: [
      "Whether you list your home with a realtor or decide to do For Sale By Owner, we strive to deliver the fair value of your property.",
      "Our goal is to close within 30 days so that you can get cash in your pocket as soon as possible.",
      "We specialize in fixer-uppers and understand that not all homes are show-ready. Though we may need to share pictures amongst our team, showings to the public are not necessary.",
      "We understand that every family's situation is unique. We have a list of possible closing strategies that are not traditional because we want everyone in the transaction to win.",
    ],
  };

  const assistingBuyers = {
    sectionHeading: "Assisting Buyers",
    description:
      "We'll bring you exactly what you're looking for with fast and friendly service.",
    itemCount: 4,
    buttonText: [
      "Delivering Your Ideal Buy Box",
      "We're a Center For Resources",
      "Bringing You The Best Deal Possible",
      "Building Long-Term Relationships",
    ],
    dropDownText: [
      "We work hard to attain properties that are ideal for the work you do.",
      "New to flipping, or seasoned professional, we thrive on networking with a wide range of resources in the industry to help you with your endeavor. (For example, title companies, realtors, hard-money lenders, etc.)",
      "We'll bring you properties where all parties receive the best and fair value in the deal.",
      "Trust and good communication are foundational in our work. We strive to keep a steady flow of business with like-minded companies for years to come.",
    ],
  };

  const alternateLogoStyle = {
    width: "350px",
    height: "350px",
  };

  return (
    <section className={style.container}>
      <section className={style.innerContainer}>
        <CircleImage
          imageURL={aloftLogo}
          imageAlt={"Picture"}
          alternateStyle={alternateLogoStyle}
        />
        <Title text={"Aloft Property Investments"} />
        <Tagline
          text={"We specialize in solving your property problems fast!"}
        />
      </section>
      <section className={style.innerContainerAlt}>
        <ServiceDisplay displayInformation={assistingSellers} />
        <ServiceDisplay displayInformation={assistingBuyers} />
      </section>
    </section>
  );
}
