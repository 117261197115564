import { Route, Routes, Navigate } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import Nav from './components/Nav';
import Home from './components/pages/Home';
import About from './components/pages/About';
// import Projects from './components/pages/Projects';
import Info from './components/pages/Info';
import Contact from "./components/pages/Contact";

function App() {
  return ( 
    <>
      <ScrollToTop />
      <Nav></Nav>
      <Routes>
        <Route index path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/info' element={<Info />} />
        <Route path='/contact' element={<Contact />} />
        <Route path="*" element={<Navigate to="/" replace />}
        />
        {/* <Route path='/projects' element={<Projects />} /> */}
      </Routes>
    </>
  ) 
}

export default App;
