import style from './CircleImage.module.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default function CircleImage({imageURL, imageAlt, alternateStyle}) {

  return (
    <section className={style.imageContainer}>
      <LazyLoadImage src={imageURL} alt={imageAlt} style={alternateStyle}/>
    </section>
  )

}