import { useState, useEffect, useRef } from 'react';
import style from './InfoButton.module.css';
import Description from './Description';

export default function InfoButton({ buttonText, dropDownText }) {

  const [active, setActive] = useState(false);

  const listItemRef = useRef(null);

  const alternateDescriptionStyle = {
    textAlign: 'start',
    marginTop: '1rem',
    border: '3px solid black',
    borderRadius: '10px',
    padding: '1rem',
    fontSize: '1.1rem',
  }
  
  useEffect(() => {

    if(active) {
      listItemRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      });
    }

  }, [active])

  return (

    <li ref={listItemRef} className={style.listItemContainer}>
      <button className={style.infoButton} onClick={() => { setActive(!active) }}>
        {buttonText}
      </button>
      {active && <Description description={dropDownText} alternateStyle={alternateDescriptionStyle} />}
    </li>

  )

}