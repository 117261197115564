import CircleImage from './CircleImage'
import style from './Profile.module.css';

export default function Profile({ personInfo }) {

  let phoneNumber = null;

  if(personInfo.phoneNumber !== null) {
    phoneNumber = <span className={style.phoneNumber}>{personInfo.phoneNumber}</span>
  }

  return (
    <section className={style.profileContainer}>
      <h2 className={style.name}>{personInfo.name}</h2>
      <CircleImage imageURL={personInfo.profileImage} imageAlt={personInfo.imageAlt}/>
      <p className={style.occupation}>{personInfo.occupation}</p>
      <address className={style.contactContainer}>
        {phoneNumber}
        <span className={style.email}>{personInfo.email}</span>
      </address>
    </section>
  )

}